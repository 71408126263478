<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(row, wrap, v-if="auditoria.id")
      v-flex(lg12)
        v-card.elevation-1.pa-1
          v-flex.md8
            span.title {{ auditoria.auditado.nome }}
            p.body-1.font-weight-light {{ auditoria.auditado.identificador }}
          //- v-flex.md4.xs12
            v-select(label="Status", :items="allStatus", :disabled="!allStatus.length", item-text="nome", item-value="id", color="gray", v-model="filter.status", name="status", :filter="this.$root.customFilter")
    v-layout(row, wrap, v-if="auditoria.id")
      v-flex.xs12
        v-card.elevation-1.pa-3
          v-layout(row, wrap).pa-0
            v-flex.md6.xs6
              span.title {{ auditoria.questionario.nome }}
            v-flex.md3.xs3.text-xs-center
              v-switch.mt-0(:false-value="0", :true-value="1", :color="filter.abertas ? 'success': ''", :label="filter.abertas ? 'Somente questões abertas' : 'Somente questões fechadas'", v-model="filter.abertas")
            v-flex.md3.xs3.text-xs-right
              v-chip(label, small, :color="['#808080', '#EC971F', '#318837', '#C9302C'][auditoria.status]").text-uppercase.white--text.font-weight-bold {{ auditoria.status | statusAuditoria }}

    v-layout(row, wrap, v-if="auditoria.id")
      v-flex.xs12
        v-card.elevation-1
          v-layout(row, wrap)
            v-flex(v-if="listCategorias && listCategorias.length").md12
              v-expansion-panel.elevation-0
                v-expansion-panel-content(v-for='categoria in listCategorias', :key='categoria.idCategoria')
                  template(v-slot:header='')
                    span.title(style="position: relative; left: -4px") {{ categoria.nomeCategoria }}
                  v-card
                    v-card-text
                      v-layout(row, wrap).pa-2
                        v-flex(v-for='questao in categoria.questoes', :key='categoria.idQuestao', @click="openDialogResultado(questao)").md12.conteudo
                          v-layout(row, wrap).mx-0
                            v-flex.md10
                              span.subheading {{ questao.nomeQuestao }}
                              br
                              span(v-if="questao.dataLimite").caption Data de Referência: {{ questao.dataReferencia | moment('DD/MM/YYYY') }} - Data Limite: {{ questao.dataLimite | moment('DD/MM/YYYY') }}
                              span(v-if="!questao.dataLimite").caption Data de Referência: {{ questao.dataReferencia | moment('DD/MM/YYYY') }}
                              br
                              v-chip(v-if="questao.resultado && questao.resultado.anexos && questao.resultado.anexos.length", label, small, color="#E6E6E6").text-uppercase.font-weight-medium.caption.black--text.mx-0 {{ questao.resultado.anexos.length }} Evidências anexadas
                              v-chip(v-if="questao.localizacao", label, small, color="#C9302C").mx-0.text-uppercase.font-weight-medium.caption.white--text Requer localização
                            v-flex.md2.text-xs-right
                              v-chip(v-if="questao.resultado && questao.resultado != 0", label, small, :color="$root && $root.bgStatusResultado[questao.resultado.status] && $root.bgStatusResultado[questao.resultado.status].bgColor").text-uppercase.font-weight-medium.caption.white--text {{ questao.resultado.status | statusResultadoValor }}
                              v-chip(v-if="!questao.resultado", label, small, color="#E6E6E6").text-uppercase.font-weight-medium.caption.black--text Não respondido
                          v-divider
      v-flex(v-if="!listCategorias || !listCategorias.length").md12.text-xs-center
        span.headline Nenhuma informação encontrada!

    v-dialog(v-model="dialogResultado", width="auto", scrollable, max-width="1000px", persistent)
      v-card
        form(@submit.prevent="salvar")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline {{ resultadoAux.nomeQuestao }}
              v-flex.md1.text-xs-right.mt-0
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogResultado = false; resultadoAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-card-text.white
              v-layout(row, wrap)
                v-flex.md9
                  span.subheading {{ resultadoAux.nomeQuestao }}
                  br
                  span(v-if="resultadoAux.dataLimite").caption Data de Referência: {{ resultadoAux.dataReferencia | moment('DD/MM/YYYY') }} - Data Limite: {{ resultadoAux.dataLimite | moment('DD/MM/YYYY') }}
                  span(v-if="!resultadoAux.dataLimite").caption Data de Referência: {{ resultadoAux.dataReferencia | moment('DD/MM/YYYY') }}
                  br
                  v-chip(v-if="resultadoAux.localizacao", label, small, color="#C9302C").mx-0.text-uppercase.font-weight-medium.caption.white--text Requer localização
                v-flex.md3.pa-1.text-xs-right
                  v-chip(v-if="resultadoAux.resultado && resultadoAux.resultado != 0", label, small, :color="$root && $root.bgStatusResultado[resultadoAux.resultado.status] && $root.bgStatusResultado[resultadoAux.resultado.status].bgColor && $root.bgStatusResultado[resultadoAux.resultado.status].bgColor").text-uppercase.font-weight-medium.caption.white--text {{ resultadoAux.resultado.status | statusResultadoValor }}
                  v-chip(v-if="!resultadoAux.resultado", label, small, color="#E6E6E6").text-uppercase.font-weight-medium.caption.black--text Não respondido
                v-flex.md12
                  v-radio-group(v-model="resultadoAux.opcao_questao_id", row, :disabled="!edicaoHabilitada")
                    v-radio(color="primary",  v-for='opcao in resultadoAux.opcoesQuestao', :key='opcao.id', :label='`${opcao.texto}`', :value='opcao.id', @change="getMinEvidencias(opcao)").mt-2

                v-flex.md12
                  span.subheading.font-weight-bold Dica:
                  v-btn(v-if="resultadoAux.dicaImagemQuestao", flat, color="black", type="button", @click="dialogDicaImagem = true; dicaImagemAux = resultadoAux.dicaImagemQuestao").font-weight-bold.text-none.body-1.pa-2.mx-2.my-0.ma-0.pa-2 Visualizar imagem
                  br
                  span.subheading {{ resultadoAux.dicaQuestao }}

                v-flex(v-if="resultadoAux && resultadoAux.resultado && resultadoAux.resultado.id && resultadoAux.opcao_questao_id").md12
                  span.subheading.font-weight-bold Evidências:
                  span.body-2.red--text É necessário inserir ao menos {{ minEvidencias }} evidência(s). O não envio das evidência pode invalidar a questão.
                  v-layout(row, wrap, v-if="resultadoAux.resultado && resultadoAux.resultado.anexos && resultadoAux.resultado.anexos.length").mt-2
                    v-flex(v-for='(evidencia, index) in resultadoAux.resultado.anexos', :key='evidencia.id', :class="index == 0 ? 'ml-0 my-3 ml-3 ma-3' : 'ma-3'").md1.xs1.text-xs-center.anexo
                      img(v-bind:src='evidencia.arquivo', width="95%", height="70" @click="visualizarEvidencia(evidencia)").mx-0.pa-0
                      v-tooltip(top)
                        v-btn(icon, dark, small, slot="activator", color="danger", @click="deleteEvidencia(evidencia)").button-remove
                          v-icon(color="white", size="18") delete_forever
                        span Remover evidência
                      v-chip(v-if="evidencia.feedbacks_anexos && evidencia.feedbacks_anexos.length", label, small, color="#E6E6E6",  @click="visualizarEvidencia(evidencia)").mx-0.font-weight-medium.caption.black--text.evidencias-chip {{ evidencia.feedbacks_anexos.length }} feedback(s)
                      br
                      span(@click="visualizarEvidencia(evidencia)").font-medium-bold.body-1 {{ evidencia.createdAt | moment('DD/MM/YYYY  HH:mm') }}

                  v-layout(v-if="edicaoHabilitada || (resultadoAux.resultado.anexos.length < minEvidencias)").mt-2
                    v-flex.md1.xs1.text-xs-center.novo-anexo.ma-3.justify-center
                      v-icon(v-if="!loadingUpload", color="black",  @click='pickFile') cloud_upload
                      div
                        v-progress-circular(v-if="loadingUpload", indeterminate, size="25", color='primary').justify-center
                      br
                      span.font-weight-bold.body-1 {{ loadingUpload ? "Enviando evidência" : "Enviar nova evidência" }}
                      br

          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap).pa-1
              v-flex.xs12.text-xs-right
                v-btn(color="black", flat, @click.native="dialogResultado = false; resultadoAux = {};") Fechar
                v-btn(v-if="resultadoAux.resultado && $root.user.permissao == 0", color="primary",  @click.native="dialogStatusResultado = true; statusResultado = resultadoAux.resultado.status").elevation-0.white--text Alterar Status
                v-btn(type="submit", color="bt-primary", :loading='loading', :disabled="!edicaoHabilitada || !resultadoAux.opcao_questao_id").elevation-0.white--text Salvar

    v-dialog(ref="dialogEvidencia", v-model="dialogEvidencia", width="auto", scrollable, max-width="900px", persistent)
      v-card
        v-card-title.headline.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.subheading Evidência anexada em {{ evidenciaAux.createdAt | moment('DD/MM/YYYY  HH:mm') }}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogEvidencia = false; evidenciaAux = {}").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-card-text
          v-layout(v-if="user.permissao === 0",row, wrap).pa-0.mx-0.my-0.ma-0.grey.lighten-2
            v-flex.my-1

              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color="black", @click="addTextBox").mx-1 title
                span Adicionar texto

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="addCircle").mx-1 panorama_fish_eye
                span Desenhar círculo

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="addProhitedIcon").mx-1 do_not_disturb
                span Errado

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="addCheckIcon").mx-1 task_alt
                span Correto

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="addSquare").mx-1 crop_square
                span Desenhar quadrado

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="handleDeleteSelected").mx-1 delete
                span Remover objeto selecionado

              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="handleDeleteAll").mx-1 delete_forever
                span Limpar todos os objetos


              v-divider(vertical).white
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-icon(v-on='on', color='black', @click="handleSaveCanvas").mx-1 save
                span Salvar imagem

              v-divider(vertical).white
              v-menu()
                template(v-slot:activator='{ on: menu }')
                  v-tooltip(top)
                    template(v-slot:activator='{ on: tooltip }')
                      v-icon(v-on='{...tooltip, ...menu}', :color="selectedColor.color").mx-1 format_color_text
                    span Escolher cor
                v-list.grey.lighten-2
                  v-list-tile(v-for='(item, index) in itemsColors' :key='index' @click="setColor(item)").pa-0.mx-0.my-0.ma-0
                    v-icon(large, :color="item.color", style="") stop
                    v-list-tile-title {{ item.nome }}


          v-divider(v-if="user.permissao === 0").grey.lighten-1
          v-layout(row, wrap)
            v-flex.md12.text-xs-center
                vue-fabric(v-if="user.permissao === 0", ref="canvas" id="canvas" :width="width" :height="height" @selection:created="selected" @selection:updated="selected")
                img(v-if="user.permissao !== 0", :src="evidenciaAux.arquivo")

          v-layout(row, wrap).mt-3
            v-flex.md12.text-xs-center
              v-autocomplete(label="Escolher feedback", :items="listFeedbacks", :disabled="!listFeedbacks.length", item-text="texto", item-value="id", color="primary", v-model="feedback.id", append-outer-icon="add_circle", @click:append-outer="addFeedback(feedback.id)")
          v-layout(row, wrap)
            v-flex.md12.text-xs-center
              v-data-table(:headers='headersFeedbacks', :search='search', :items='evidenciaAux.feedbacks_anexos', :rows-per-page-items="[25,50,75,{ text:'Todos','value':-1 }]").elevation-0
                template(slot='items', slot-scope='props')
                  td.text-xs-left {{ props.item.id }}
                  td.text-xs-left {{ props.item.feedback.texto }}
                  td.text-xs-left {{ props.item.feedback.categoria_feedback.texto }}
                  td.text-xs-right
                    v-tooltip(top)
                      v-btn(slot="activator", flat, icon, fab, dark, color="red", small, @click="deleteFeedback(props.item)")
                        v-icon delete
                      span Excluir
        v-card-actions.headline.grey.lighten-2
          v-layout(row, wrap)
            v-flex.xs12.text-xs-right
              v-btn(color="black", flat, @click.native="dialogEvidencia = false; evidenciaAux = {}") Fechar

    v-dialog(v-model="dialogDicaImagem", width="auto", scrollable, max-width="700px", persistent)
      v-card
        v-card-title
          v-layout(row, wrap)
            v-flex.md11
              span.subheading
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="dialogDicaImagem = false; dicaImagemAux = {}").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-card-text
          v-layout(row, wrap)
            v-flex.md12.text-xs-center
              img(v-bind:src='dicaImagemAux', width="70%", height="auto")
        v-card-actions
          v-layout(row, wrap)
            v-flex.xs12.text-xs-right
              v-btn(color="black", flat, @click.native="dialogDicaImagem = false; dicaImagemAux = {}") Fechar

    v-dialog(v-if="resultadoAux", v-model="dialogStatusResultado", width="auto", scrollable, max-width="500px", persistent)
      v-card
        form(@submit.prevent="salvarResultado")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline Alterar Status
              v-flex.md1.text-xs-right.mt-0
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogStatusResultado = false;").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-card-text
            v-flex.md12.mb-0.my-0.mt-3
              v-radio-group(collumn, v-model="statusResultado").pa-0.my-0.mx-0.mt-0.mb-0
                v-radio(color="primary", v-for='status in allStatus', :key='status.id', :value='status.id')
                  template(v-slot:label='')
                    span.black--text.font-weight-regular {{ status.nome }}
              v-text-field(label="Revisão", v-model="textoRevisao")
          v-card-actions.headline.grey.lighten-2
            v-flex.xs12.text-xs-right
              v-btn(color="black", flat, @click.native="dialogStatusResultado = false;") Fechar
              v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

    input(type='file', style='display: none', ref='fileChoose',  @change='onFilePicked')

</template>

<script>
import _ from "lodash";
import IMG_CIRCLE from "@/assets/images/circle.png";
import IMG_ARROW from "@/assets/images/arrow-red.png";
import IMG_CLOSE from "@/assets/images/close-red.png";
import IMG_SQUARE from "@/assets/images/square-red.png";

export default {
    data() {
        return {
            width: 750,
            height: 500,
            feedback: {},
            auditoria: {},
            resultado: {},
            resultadoAux: {},
            evidenciaAux: {},
            dicaImagemAux: {},
            minEvidencias: 0,
            statusResultado: 0,
            textoRevisao: "",
            loadingUpload: false,
            edicaoHabilitada: false,
            itemsColors: [
                {
                    id: 1,
                    nome: "Branco",
                    color: "white",
                },
                {
                    id: 2,
                    nome: "Preto",
                    color: "black",
                },
                {
                    id: 3,
                    nome: "Vermelho",
                    color: "red",
                },
                {
                    id: 4,
                    nome: "Verde",
                    color: "green",
                },
                {
                    id: 5,
                    nome: "Azul",
                    color: "blue",
                },
            ],
            selectedColor: {
                id: 3,
                nome: "Vermelho",
                color: "red",
            },
            filter: {
                search: "",
                status: null,
                abertas: 1,
            },
            allStatus: [
                { id: 1, nome: "Aguardando Revisão" },
                { id: 2, nome: "Válido" },
                { id: 3, nome: "Inválido" },
            ],
            headers: [
                { text: "#", align: "left", value: "id" },
                { text: "Nome", align: "left", value: "questionario.nome" },
                {
                    text: "Auditado",
                    align: "left",
                    value: "auditado.identificador",
                },
                {
                    text: "Auditor Responsável",
                    align: "left",
                    value: "usuario.nome",
                },
                { text: "Data de Início", align: "left", value: "data_inicio" },
                { text: "Status", align: "left", value: "status" },
                { text: "Ações", align: "right", sortable: false },
            ],
            headersFeedbacks: [
                { text: "#", align: "left", value: "id" },
                { text: "Nome", align: "left", value: "feedback.texto" },
                {
                    text: "Categoria",
                    align: "left",
                    value: "categoria_feedback.texto",
                },
                { text: "Ações", align: "right", sortable: false },
            ],
            search: "",
            loading: false,
            dialogResultado: false,
            dialogEvidencia: false,
            dialogStatusResultado: false,
            dialogDicaImagem: false,
            categoriasQuestoes: [],
            listFeedbacks: [],
            listIcons: [
                {
                    id: 2,
                    url: IMG_SQUARE,
                },
                {
                    id: 3,
                    url: IMG_CIRCLE,
                },
                {
                    id: 4,
                    url: IMG_ARROW,
                },
                {
                    id: 5,
                    url: IMG_CLOSE,
                },
            ],
            colorCanvas: "#FF0000",
        };
    },
    computed: {
        user() {
            return _.cloneDeep(this.$store.getters.user);
        },
        list() {
            let categoriasQuestoes = _.cloneDeep(this.categoriasQuestoes);
            return categoriasQuestoes;
        },
        listCategorias() {
            const vm = this;
            let categorias = [];
            let categoriasQuestoes = [];

            let questoes = vm.filter.abertas
                ? vm.auditoria.questoes
                : vm.auditoria.questoesFechadas;

            _.map(questoes, (obj) => {
                categorias.push({
                    idCategoria: obj.categoria_questao_id,
                    nomeCategoria: obj.categoria_questao.texto,
                    idQuestao: obj.id,
                    nomeQuestao: obj.texto,
                    dicaQuestao: obj.observacao,
                    dicaImagemQuestao: obj.dica_imagem,
                    dataReferencia: obj.dataReferencia,
                    dataLimite: obj.dataLimite,
                    localizacao: obj.localizacao,
                    opcoesQuestao: obj.opcoes_questao,
                    resultados: obj.resultados,
                    resultado: obj.resultado,
                });
            });
            let groupCategoriasById = _.groupBy(categorias, "idCategoria");
            for (let prop in groupCategoriasById) {
                categoriasQuestoes.push({
                    idCategoria: groupCategoriasById[prop][0].idCategoria,
                    nomeCategoria: groupCategoriasById[prop][0].nomeCategoria,
                    questoes: vm.getQuestoes(groupCategoriasById[prop]),
                });
            }
            return categoriasQuestoes;
        },
    },
    mounted() {
        const vm = this;
        if (vm.$route.params.id) {
            vm.getAuditoria(vm.$route.params);
        }
    },
    methods: {
        clearCanvas() {
            let options = {
                imgUrl: this.evidenciaAux.arquivo,
                width: this.width,
                height: this.height,
                opacity: 1,
                scaleX: 1,
            };
            this.$refs.canvas.clear();
            this.$refs.canvas.setBackgroundImage(options);
        },
        selected(obj, option) {},
        async salvarResultado() {
            const vm = this;
            try {
                vm.loading = true;
                vm.resultadoAux.resultado.status = vm.statusResultado;
                vm.resultadoAux.resultado.revisao = vm.textoRevisao;
                const response = vm.$axios.put(
                    `/resultado/${vm.resultadoAux.resultado.id}`,
                    vm.resultadoAux.resultado,
                );
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Status alterado com sucesso!",
                });
                vm.dialogStatusResultado = false;
                vm.loading = false;
            } catch (error) {
                vm.dialogStatusResultado = false;
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao alterar o status. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async saveResultado(resultadoAux) {
            const vm = this;

            try {
                vm.loading = true;
                let resultado = {};
                resultado.auditoria_id = parseInt(
                    vm.$route.params.id ? vm.$route.params.id : null,
                );
                resultado.questao_id = vm.resultadoAux.idQuestao;
                resultado.opcao_questao_id = vm.resultadoAux.opcao_questao_id;
                resultado.data_referencia = this.$moment(
                    resultadoAux.dataReferencia,
                ).format("YYYY-MM-DD");
                const response = await vm.$axios.post("/resultado", resultado);
                if (response && response.data.id) {
                    vm.resultadoAux.resultado = response.data;
                    vm.resultadoAux.resultado.anexos = [];
                    vm.resultadoAux.resultado.status = response.data.status;
                    vm.loading = false;
                }
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questão respondida com sucesso. Verifique se a questão solicita envio de anexos!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao responder a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
            vm.loading = false;
        },

        async updateResultado(resultadoAux) {
            const vm = this;
            try {
                vm.loading = true;
                let resultado = {};
                resultado.id = vm.resultadoAux.resultado.id;
                resultado.questao_id = vm.resultadoAux.idQuestao;
                resultado.opcao_questao_id = vm.resultadoAux.opcao_questao_id;
                resultado.data_referencia = this.$moment(
                    resultadoAux.dataReferencia,
                ).format("YYYY-MM-DD");
                const response = await vm.$axios.put(
                    `/resultado/${resultado.id}`,
                    resultado,
                );
                if (response) {
                    vm.resultadoAux.resultado = response.data;
                }
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Questão salva com sucesso! Verifique se a questão solicita envio de anexos!",
                });
                vm.dialogResultado = false;
                vm.getAuditoria(vm.$route.params);
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar a questão. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },

        async salvar() {
            const vm = this;
            try {
                vm[
                    vm.resultadoAux.resultado && vm.resultadoAux.resultado.id
                        ? "updateResultado"
                        : "saveResultado"
                ](vm.resultadoAux);
            } catch (error) {
                window.getApp.$emit("APP_ALERT", { color: "red", text: error });
                vm.loading = false;
            }
        },
        getQuestoes(questoesAll) {
            let questoes = [];
            _.map(questoesAll, (q) => {
                questoes.push({
                    idQuestao: q.idQuestao,
                    nomeQuestao: q.nomeQuestao,
                    dicaQuestao: q.dicaQuestao,
                    dicaImagemQuestao: q.dicaImagemQuestao,
                    dataReferencia: q.dataReferencia,
                    dataLimite: q.dataLimite,
                    localizacao: q.localizacao,
                    opcoesQuestao: q.opcoesQuestao,
                    resultados: q.resultados,
                    resultado: q.resultado,
                });
            });
            return questoes;
        },
        async getAuditoria(params) {
            const vm = this;
            try {
                const response = await vm.$axios.get(
                    `/auditoria-resultado/${params.id}`,
                    {
                        params: {
                            fields: "auditado,usuario,questionario,opcoes_questao,revisao",
                        },
                    },
                );
                vm.auditoria = response.data || {};
                vm.loading = false;
            } catch (error) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Houve um erro ao buscar a auditoria, tente novamente",
                });
                vm.loading = false;
            }
        },
        async getFeedbacks() {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.get(`/feedback`, {
                    params: { fields: "categoria_feedback" },
                });
                vm.listFeedbacks = response.data.rows || [];
                vm.loading = false;
            } catch (error) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Houve um erro ao buscar os feedbacks, tente novamente",
                });
                vm.loading = false;
            }
        },
        async openDialogResultado(questao) {
            const vm = this;
            let dataAtual = this.$moment().format("YYYY-MM-DD");
            vm.dialogResultado = true;
            vm.resultadoAux = questao;

            if (
                questao.resultado &&
                questao.opcoesQuestao &&
                questao.opcoesQuestao.length
            ) {
                vm.resultadoAux.opcao_questao_id = _.find(
                    questao.opcoesQuestao,
                    { id: questao.resultado.opcao_questao_id },
                ).id;
                vm.statusResultado =
                    questao &&
                    questao.resultado.status &&
                    !_.isNil(questao.resultado.status)
                        ? questao.resultado.status
                        : null;
                vm.statusResultado = 1;
                vm.minEvidencias = _.find(questao.opcoesQuestao, {
                    id: questao.resultado.opcao_questao_id,
                }).min_anexos;
            }

            if (questao.localizacao) {
                vm.edicaoHabilitada = false;
            } else if (this.user.permissao == 0) {
                vm.edicaoHabilitada = true;
            } else {
                if (
                    questao.resultado &&
                    questao.resultado.data_referencia &&
                    this.$moment(questao.dataReferencia).format("YYYY-MM-DD") !=
                        this.$moment(questao.resultado.data_referencia).format(
                            "YYYY-MM-DD",
                        )
                ) {
                    vm.edicaoHabilitada = false;
                } else if (
                    vm.auditoria.status == 1 &&
                    questao &&
                    questao.resultado &&
                    _.isNil(questao.resultado.status) &&
                    questao.resultado.status == 3
                ) {
                    vm.edicaoHabilitada = true;
                } else if (
                    this.$moment(dataAtual).isAfter(questao.dataLimite)
                ) {
                    vm.edicaoHabilitada = false;
                } else if (!questao.resultado) {
                    vm.edicaoHabilitada = true;
                } else if (questao.resultado && !questao.resultado.status) {
                    vm.edicaoHabilitada = true;
                } else if (questao.resultado && questao.resultado.status == 1) {
                    vm.edicaoHabilitada = false;
                } else if (questao.resultado && questao.resultado.status == 2) {
                    vm.edicaoHabilitada = false;
                } else if (questao.resultado && questao.resultado.status == 3) {
                    vm.edicaoHabilitada = true;
                } else if (questao.resultado && this.user.permissao != 0) {
                    vm.edicaoHabilitada = false;
                } else {
                    vm.edicaoHabilitada = true;
                }
            }
        },
        getMinEvidencias(opcao) {
            const vm = this;
            vm.minEvidencias = opcao.min_anexos;
        },
        pickFile() {
            const vm = this;
            vm.$refs.fileChoose.click();
        },
        async saveResultadoAnexo(params) {
            const vm = this;
            try {
                vm.loading = true;
                let resultadoAnexo = {};
                resultadoAnexo.resultado_valor_id =
                    vm.resultadoAux.resultado.id;
                resultadoAnexo.arquivo = params.location;
                const response = await vm.$axios.post(
                    "/resultado-anexo",
                    resultadoAnexo,
                );
                vm.resultadoAux.resultado.anexos.push(response.data);
                vm.loading = false;
            } catch (error) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Ocorreu um erro ao enviar a evidência. Tente novamente!",
                });
                vm.loading = false;
            }
        },
        async sendNewAttachment(attachment) {
            const vm = this;

            vm.fileAnexo = attachment;
            let formData = new FormData();
            formData.append("file", vm.fileAnexo, "imagem.jpg");
            vm.loadingUpload = true;
            try {
                const responseFile = await vm.$axios.post(
                    "resultado-anexo/arquivo",
                    formData,
                    {
                        headers: { "content-type": "multipart/form-data" },
                    },
                );
                vm.evidenciaAux.arquivo = responseFile.data.location;
                vm.loadingUpload = false;
                vm.saveResultadoAnexo(responseFile.data);
            } catch (ex) {
                vm.loadingUpload = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Ocorreu um erro ao salvar a evidência. Tente novamente!",
                });
            }
        },
        async onFilePicked(e) {
            const vm = this;
            const files = e.target.files;
            if (files[0] !== undefined) {
                let image_file = files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image_file);
                reader.onload = (ev) => {
                    let image_url = ev.target.result;
                    let image = document.createElement("img");

                    image.src = image_url;
                    image.onload = (e) => {
                        let canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        if (e.target.width > e.target.height) {
                            let ratio = vm.width / e.target.width;
                            canvas.width = vm.width;
                            canvas.height = e.target.height * ratio;
                        } else {
                            let ratio = vm.height / e.target.height;
                            canvas.width = e.target.width * ratio - 1;
                            canvas.height = vm.height;
                        }
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                        let new_image_url = ctx.canvas.toDataURL(
                            "image/jpeg",
                            0.7,
                        );

                        fetch(new_image_url)
                            .then((r) => r.blob())
                            .then((blobFile) => {
                                let file = new File([blobFile], "imagem", {
                                    type: "image/jpeg",
                                });

                                vm.sendNewAttachment(file);
                            });
                    };
                };
            }
        },
        async deleteEvidencia(evidencia) {
            const vm = this;
            try {
                const res = await vm.$confirm(
                    "Deseja realmente excluir?<br> Essa ação não poderá ser desfeita!",
                );
                if (!res) return;
                vm.loading = true;
                const response = await vm.$axios.delete(
                    `/resultado-anexo/${evidencia.id}/arquivo`,
                );
                const index = _.findIndex(vm.resultadoAux.resultado.anexos, {
                    id: evidencia.id,
                });
                let anexos = _.cloneDeep(vm.resultadoAux.resultado.anexos);
                vm.resultadoAux.resultado.anexos = anexos;
                _.remove(anexos, { id: evidencia.id });
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao remover a evidência. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async deleteFeedback(feedback) {
            const vm = this;
            try {
                const response = await vm.$axios.delete(
                    `/feedback-anexo/${feedback.id}`,
                );
                if (response) {
                    const index = _.findIndex(
                        vm.evidenciaAux.feedbacks_anexos,
                        { id: feedback.id },
                    );
                    let feedbacks_anexos = _.cloneDeep(
                        vm.evidenciaAux.feedbacks_anexos,
                    );
                    _.remove(feedbacks_anexos, { id: feedback.id });
                    vm.evidenciaAux.feedbacks_anexos = feedbacks_anexos;
                }
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao remover o feedback. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async visualizarEvidencia(evidencia) {
            const vm = this;
            vm.dialogEvidencia = true;
            vm.evidenciaAux = evidencia;
            vm.getFeedbacks();
            if (vm.user.permissao === 0) {
                let options = {
                    imgUrl: evidencia.arquivo,
                    width: vm.width,
                    height: vm.height,
                    opacity: 1,
                    scaleX: 1,
                };
                vm.$refs.canvas.setBackgroundImage(options);
            }
        },
        async addFeedback(feedback_id) {
            const vm = this;
            try {
                if (!feedback_id) {
                    return window.getApp.$emit("APP_ALERT", {
                        color: "red",
                        text: "É necessário selecionar um feedback!",
                    });
                } else {
                    let data = {};
                    data.resultado_valor_anexo_id = vm.evidenciaAux.id;
                    data.feedback_id = feedback_id;
                    const response = await vm.$axios.post(
                        "/feedback-anexo",
                        data,
                    );
                    if (response && response.data && response.data.id) {
                        response.data.feedback = _.find(vm.listFeedbacks, {
                            id: response.data.feedback_id,
                        });
                        if (vm.evidenciaAux.feedbacks_anexos) {
                            vm.evidenciaAux.feedbacks_anexos.push(
                                response.data,
                            );
                        } else {
                            vm.evidenciaAux.feedbacks_anexos = [];
                            vm.evidenciaAux.feedbacks_anexos.push(
                                response.data,
                            );
                        }
                    }
                }
                vm.feedback = {};
            } catch (error) {
                vm.feedback = {};
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao adicionar a categoria. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async urlToJpegBlob(imageUrl) {
            try {
                // Fetch the image data from the URL
                const response = await fetch(imageUrl);

                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch image: ${response.status} ${response.statusText}`,
                    );
                }

                // Convert the image data to ArrayBuffer
                const arrayBuffer = await response.arrayBuffer();

                // Create a Blob with MIME type "image/jpeg"
                const jpegBlob = new Blob([arrayBuffer], {
                    type: "image/jpeg",
                });

                return jpegBlob;
            } catch (error) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Houve um erro ao salvar a imagem, tente novamente",
                });
                vm.loading = false;
                return null;
            }
        },

        // VUE FABRIC METHODS
        handleAdd(url) {
            const vm = this;
            vm.$refs.canvas.createImage(url);
        },
        handleDelete() {
            const vm = this;
            vm.$refs.canvas.removeCurrentObj();
        },
        addTextBox() {
            const vm = this;
            vm.$refs.canvas.createItext("Escreva aqui", {
                left: 30,
                top: 30,
                type: "text",
                fill: vm.colorCanvas,
            });
        },
        addCircle() {
            const vm = this;
            vm.$refs.canvas.createCircle({
                color: vm.colorCanvas,
                stroke: vm.colorCanvas,
                type: "circle",
                strokeWidth: 4,
            });
        },
        addCheckIcon() {
            const vm = this;
            const checkSVGData =
                "M39.04,7.604l-2.398-1.93c-1.182-0.95-1.869-0.939-2.881,0.311L16.332,27.494l-8.111-6.739 c-1.119-0.94-1.819-0.89-2.739,0.26l-1.851,2.41c-0.939,1.182-0.819,1.853,0.291,2.78l11.56,9.562c1.19,1,1.86,0.897,2.78-0.222 l21.079-25.061C40.331,9.294,40.271,8.583,39.04,7.604z";
            const options = {
                width: 50,
                height: 50,
                fillColor: vm.colorCanvas,
                left: 0,
                top: 0,
                strokeColor: vm.colorCanvas,
                fillColor: vm.colorCanvas,
                strokeWidth: 2,
                type: "svg",
            };
            vm.$refs.canvas.drawByPath(checkSVGData, options);
        },
        addProhitedIcon() {
            const vm = this;
            const prohibitedSVGData =
                "M18 0C8.059 0 0 8.059 0 18s8.059 18 18 18s18-8.059 18-18S27.941 0 18 0zm13 18c0 2.565-.753 4.95-2.035 6.965L11.036 7.036A12.916 12.916 0 0 1 18 5c7.18 0 13 5.821 13 13zM5 18c0-2.565.753-4.95 2.036-6.964l17.929 17.929A12.93 12.93 0 0 1 18 31c-7.179 0-13-5.82-13-13z";
            const options = {
                width: 100,
                height: 100,
                fillColor: vm.colorCanvas,
                left: 50,
                top: 50,
                strokeColor: vm.colorCanvas,
                fillColor: vm.colorCanvas,
                strokeWidth: 2,
                type: "svg",
            };
            vm.$refs.canvas.drawByPath(prohibitedSVGData, options);
        },
        addSquare() {
            const vm = this;
            vm.$refs.canvas.createRect({
                width: 60,
                height: 60,
                stroke: vm.colorCanvas,
                type: "rect",

                strokeWidth: 4,
            });
        },
        handleDeleteSelected() {
            const vm = this;
            vm.$refs.canvas.removeCurrentObj();
        },
        handleDeleteAll() {
            const vm = this;
            vm.$refs.canvas.clear();
            let options = {
                imgUrl: vm.evidenciaAux.arquivo,
                width: vm.width,
                height: vm.height,
                opacity: 1,
                scaleX: 1,
            };
            vm.$refs.canvas.setBackgroundImage(options);
        },
        setColor(item) {
            const vm = this;
            vm.selectedColor = item;
            vm.colorCanvas = item.color;
        },
        buildSvgImageUrl(svg) {
            const vm = this;
            return vm.$root.b64toBlob(window.btoa(svg));
            // let b64 = window.btoa(svg);
            // return "data:image/svg+xml;base64," + b64;
        },
        async updateImage(new_image) {
            const vm = this;
            const blobNewImage = vm.$root.b64toBlob(new_image);

            let formData = new FormData();
            formData.append("file", blobNewImage, "imagem.jpg");

            const res = await vm.$axios.post(
                "resultado-anexo/arquivo",
                formData,
                {
                    headers: { "content-type": "multipart/form-data" },
                },
            );

            const response = await vm.$axios.put(
                `/resultado-anexo/${vm.evidenciaAux.id}/arquivo`,
                { ...vm.evidenciaAux, arquivo: res.data.location },
            );

            vm.evidenciaAux.arquivo = response.data.arquivo;
            vm.clearCanvas();
            vm.loading = false;

            return window.getApp.$emit("APP_ALERT", {
                color: "green",
                text: "Edição salva com sucesso!",
            });
        },
        async insertBlobImageAndObjectsIntoCanvas(blob, jsonObjects) {
            const vm = this;
            try {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                const img = new Image();
                img.src = URL.createObjectURL(blob);

                await new Promise((resolve) => {
                    img.onload = resolve;
                });

                if (img.width > img.height) {
                    let ratio = vm.width / img.width;
                    canvas.width = vm.width;
                    canvas.height = img.height * ratio;
                } else {
                    let ratio = vm.height / img.height;
                    canvas.width = img.width * ratio - 1;
                    canvas.height = vm.height;
                }

                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                jsonObjects.forEach((obj) => {
                    context.strokeStyle = obj.stroke || "red";
                    context.lineWidth =
                        obj.strokeWidth * (obj.scaleY + obj.scaleX / 3);

                    switch (obj.type) {
                        case "svg":
                            let svgPathData = "";

                            obj.path.forEach((segment) => {
                                const [command, ...parameters] = segment;
                                svgPathData +=
                                    command + " " + parameters.join(" ") + " ";
                            });

                            let p = new Path2D(svgPathData);

                            context.translate(obj.left, obj.top);
                            context.scale(
                                Math.abs(obj.scaleX),
                                Math.abs(obj.scaleY),
                            );
                            context.fillStyle = obj.fill;
                            context.fill(p);
                            context.setTransform(1, 0, 0, 1, 0, 0);

                            break;

                        case "rect":
                            context.strokeRect(
                                obj.left,
                                obj.top,
                                obj.width * obj.scaleX,
                                obj.height * obj.scaleY,
                            );
                            break;
                        case "circle":
                            let multiplicator = Math.max(
                                Math.abs(obj.scaleX),
                                Math.abs(obj.scaleY),
                            );
                            let newRadius = obj.radius * multiplicator;
                            context.beginPath();
                            context.arc(
                                obj.left + newRadius,
                                obj.top + newRadius,
                                newRadius,
                                0,
                                360,
                            );
                            context.stroke();
                            break;
                        case "text":
                            context.fillStyle = obj.fill;
                            context.font = `${obj.fontSize * obj.scaleY}px ${
                                obj.fontFamily
                            }`;
                            context.fillText(
                                obj.text,
                                obj.left,
                                obj.top + obj.height,
                            );
                            break;
                    }
                });

                let new_url = context.canvas.toDataURL("image/jpeg");

                return new_url;
            } catch (error) {
                window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Houve um erro ao salvar a imagem, tente novamente",
                });
                vm.loading = false;
                return null;
            }
        },
        async handleSaveCanvas() {
            try {
                this.loading = true;
                this.urlToJpegBlob(this.evidenciaAux.arquivo).then(
                    async (blob) => {
                        if (blob) {
                            let canvasJSON = this.$refs.canvas.toJSON();
                            let { objects } = JSON.parse(canvasJSON);
                            const res =
                                await this.insertBlobImageAndObjectsIntoCanvas(
                                    blob,
                                    objects || [],
                                );

                            if (!res) {
                                this.loading = false;
                                return window.getApp.$emit("APP_ALERT", {
                                    color: "red",
                                    text: "Erro ao salvar Edição!",
                                });
                            }

                            this.updateImage(res);
                        }
                    },
                );
            } catch (err) {
                this.loading = false;
                return window.getApp.$emit("APP_ALERT", {
                    color: "red",
                    text: "Erro ao salvar Edição!",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.anexo {
    cursor: pointer;
}
.novo-anexo {
    display: flex;
    flex-direction: column;
}
.evidencias-chip {
    cursor: pointer;
}
.button-remove {
    position: absolute;
    transform: translate(-50%, -50%);
}
.list-icons {
    background-color: 1px solid #e4e4e4;
}
.image-list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    overflow-y: auto;

    overflow: scroll;

    display: flex;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    border-top: 1px solid #e4e4e4;

    &::-webkit-scrollbar {
        display: none;
    }

    .image-wrapper {
        padding: 4vw;
        border-right: 1px solid #ebeef5;
        min-width: 20vw;

        display: -webkit-flex;
        display: -ms-flexbox;
        display: -moz-box;
        display: -webkit-box;
        display: flex;

        box-pack: center;
        -webkit--moz-box-pack: center;
        -moz-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;

        box-align: center;
        -moz-box-align: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;

        -moz-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;

        position: relative;
        img {
            width: 20vw;
            object-fit: cover;
        }
        .pt-iconfont {
            position: absolute;
            right: 0vw;
            top: 0vw;
            color: #999;
            font-size: 4.5vw;
            padding: 1vw;
        }
    }
}
</style>
